<template>
  <!-- 计费方案 - 临停计费方案 -->
  <div class="ParkingInformation">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="计费方案：">
        <el-input maxlength="20" v-model="queryForm.scheme_name" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.scheme_name = validSpace(e))" placeholder="请输入计费方案"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="临停计费方案">
      <template slot="heard-right">
        <div>
          <lbButton type="err" icon="shanchu1" @click="batchDel"
          v-if='isShowBtn("3cef6aca3b9a4bd1bf525481") || isShowBtn("cd0b4f4a59974de5beaad8de")'>删除</lbButton>
        <lbButton icon="xinzeng" @click="showAddTimeKeeping = true"  v-if='this.getUserInfo.user_name != "admin" && (isShowBtn("baed76b723cf4cbba886c7ab") || isShowBtn("62c3a299f20746f986094f30"))'>新增</lbButton>
        </div>
        <!-- <lbButton icon="xinzeng" @click="isRoutW ? $router.push('/routw-add-timekeeping-project') : $router.push('/add-timekeeping-project')" v-if='this.getUserInfo.user_name != "admin" && (isShowBtn("baed76b723cf4cbba886c7ab") || isShowBtn("62c3a299f20746f986094f30"))'>新增</lbButton> -->
      </template>
      <div class="box-card" style="text-align: left;">
        <!-- 主要内容 -->
        <!-- 表格 -->
         <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
             <template slot-scope="{row}" slot="scheme_type">
              {{ getWayName('SchemeType', row.scheme_type) }}
            </template>
             <template slot-scope="{row}" slot="scheme_details">
              <div v-html="row.scheme_details.replace(/\r\n|\n|\r/g, '<br/>')"></div>
            </template>
             <template slot-scope="{row}" slot="parking">
              <el-link type="primary" target="_blank" v-if="row.parking.length > 0"
                @click="openParking(row)">{{ row.parking.length }}</el-link>
              <span v-else>{{ row.parking.length }}</span>
            </template>
              <template slot-scope="{row}" slot="create_time">
                {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="batchEdit(row)"
                v-if='isShowBtn("c43a9f2d4be14858bdda3967") || isShowBtn("b24ffc107426427c947814a2")'>编辑</lbButton>
            </template>
          </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="scheme_name" label="计费方案"></el-table-column>
          <el-table-column label="方案类型" width="90">
            <template slot-scope="scope">
              {{ getWayName('SchemeType', scope.row.scheme_type) }}
            </template>
          </el-table-column>
          <el-table-column label="方案详情" min-width="150">
            <template slot-scope="scope">
              <div v-html="scope.row.scheme_details.replace(/\r\n|\n|\r/g, '<br/>')"></div>
            </template>
          </el-table-column>
          <el-table-column label="已配置" width="90">
            <template slot-scope="scope">
              <el-link type="primary" target="_blank" v-if="scope.row.parking.length > 0"
                @click="openParking(scope.row)">{{ scope.row.parking.length }}</el-link>
              <span v-else>{{ scope.row.parking.length }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="batchEdit(scope.row)"
                v-if='isShowBtn("c43a9f2d4be14858bdda3967") || isShowBtn("b24ffc107426427c947814a2")'>编辑</lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
      </div>
    </le-card>
    <lebo-dialog append-to-body title="已配置停车场" :isShow="isConfigParkingShow" width="30%"
      :footerSlot="true" @close="isConfigParkingShow = false" closeOnClickModal>
      <el-table :data="configParkingList" style="width: 100%" border stripe height="460">
        <el-table-column header-align="center" align="center" type="index" label="序号" width="100">
        </el-table-column>
        <el-table-column header-align="left" prop="parking_name" label="停车场">
        </el-table-column>
      </el-table>
    </lebo-dialog>
    <!-- 新增计费方案弹框 -->
    <lebo-dialog :isShow="showAddTimeKeeping" width="57%" title="新增计费方案" :footerSlot="true"
      @close="showAddTimeKeeping = false">
      <addTimeKeeping @closeDialog="closeAddTime"></addTimeKeeping>
    </lebo-dialog>
    <lebo-dialog :isShow="showEditTimeKeeping" width="57%" title="编辑计费方案" :footerSlot="true"
      @close="showEditTimeKeeping = false">
      <addTimeKeeping @closeDialog="closeAddTime" :addParkId="dialogtimekeepingId" v-if="showEditTimeKeeping">
      </addTimeKeeping>
    </lebo-dialog>
  </div>
</template>

<script>
import { getTimeCardList, delTimeOrCount } from '@/api/billingProject'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import addTimeKeeping from './components/addTimeKeeping/index.vue'
export default {
  components: { addTimeKeeping },
  data () {
    return {
      // 查询表单
      queryForm: {
        scheme_name: '',
        PageSize: 10,
        PageIndex: 1
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      isConfigParkingShow: false,
      configParkingList: [],
      showAddTimeKeeping: false, // 新增计费方案弹窗
      showEditTimeKeeping: false, // 编辑计费
      dialogtimekeepingId: '', // 临停计费id
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '计费方案',
            prop: 'scheme_name'
          }, {
            label: '方案类型',
            prop: 'scheme_type',
            slot: true
          }, {
            label: '方案详情',
            prop: 'scheme_details',
            slot: true
          }, {
            label: '已配置',
            prop: 'parking',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetTimeCardList()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['parkingList', 'queryObj', 'queryStatus']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getOptions', 'getWayName', 'getUserInfo'])
  },
  methods: {
    ...mapActions(['requestPorkingList', 'requestFindList']),
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fnGetTimeCardList () {
      const res = await getTimeCardList({
        scheme_name: this.queryForm.scheme_name,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      // console.log(res.Data.DataList);
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetTimeCardList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetTimeCardList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetTimeCardList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetTimeCardList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetTimeCardList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的计费方案, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.$msg.success('删除成功!');
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._ids = arr
              this.fnDelTimeOrCount(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的计费方案！')
      }
    },
    // 删除计费方案请求
    async fnDelTimeOrCount (obj) {
      const res = await delTimeOrCount(obj)
      // console.log(res);
      // this.$msg.error(res.Message);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetTimeCardList()
    },
    // 修改计费方案
    batchEdit (row) {
      // console.log(row);
      this.showEditTimeKeeping = true
      this.dialogtimekeepingId = row._id
      // this.$router.push({
      //   path: this.isRoutW ? '/routw-add-timekeeping-project' : '/add-timekeeping-project',
      //   query: {
      //     id: row._id
      //   }
      // })
    },
    // 查看已配置停车场
    openParking (item) {
      this.isConfigParkingShow = true
      this.configParkingList = item.parking
      // console.log(this.configParkingList);
    },
    closeAddTime () {
      this.showAddTimeKeeping = false
      this.showEditTimeKeeping = false
      this.fnGetTimeCardList()
    }
  }
}
</script>

<style scoped lang="less">.ParkingInformation {
  margin-top: 20px;

}</style>

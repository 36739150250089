<template>
  <!-- 计费方案 - 新增修改临停计费方案 -->
  <div class="box-card" style="text-align: left;">
    <!-- 主要内容 -->
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
      <el-form-item label="方案名称：" prop="scheme_name">
        <el-input maxlength="20" v-model="addForm.scheme_name" @input="(e) => (addForm.scheme_name = validSpace(e))"
          placeholder="请输入计费方案名称"></el-input>
      </el-form-item>
      <el-form-item label="方案类型：" prop="scheme_type">
        <el-select popper-class="my-select" v-model="addForm.scheme_type" placeholder="请选择方案类型">
          <el-option label="计时" :value="1"></el-option>
          <el-option label="计次" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="方案详情：" prop="scheme_details">
        <el-input type="textarea" maxlength="300" v-model="addForm.scheme_details" :rows="5" placeholder="请输入方案详情"
          @blur="addForm.scheme_details = ((addForm.scheme_details.replace(/\r\n|\n|\r/g, '<br/>')).replace(/\s/g, '')).replace(/<br\/>/g, '\n')"></el-input>
      </el-form-item>
      <div v-if="addForm.scheme_type == 1">
        <el-form-item label="免费时长：" prop="free_time">
          <div style="display: inline-block;width: 100%;">
            <el-input v-model="addForm.free_time" maxlength="9" @input="(e) => (addForm.free_time = validSpacing(e))"
              placeholder="请输入免费时长"></el-input> 分钟
            <!-- <el-checkbox style="margin-left: 15px;" v-model="addForm.free_time_iscalculate">免费时长包含计算</el-checkbox> -->
          </div>
        </el-form-item>
        <el-form-item label="计费周期：" prop="billing_cycle">
          <div style="display: inline-block;width: 100%;">
            <el-input v-model="addForm.billing_cycle" maxlength="9"
              @input="(e) => (addForm.billing_cycle = validSpacing(e))" placeholder="请输入计费周期"></el-input>分钟
          </div>
        </el-form-item>
        <el-form-item label="计费时段：" class="time-frame" prop="cost_time_period_timing">
          <div v-for="(item, index) in addForm.cost_time_period_timing" :key="index">
            <div style="display: inline-block;margin-bottom:20px;display: flex;align-items: center;"
              v-if="index < addForm.cost_time_period_timing.length - 1">
              <div class="labelWidth">
                第 {{ index + 1 }} 段
              </div>
              <el-form-item :prop="'cost_time_period_timing[' + index + '].time_duration'"
                :rules="[{ required: true, message: '请输入计费时间', trigger: 'blur' }, { pattern: /^[0-9]*$/, message: '格式错误，请输入数字', trigger: 'blur' }]">
                <el-input class="innerInput" maxlength="9" v-model="item.time_duration"
                  @input="(e) => (item.time_duration = validSpacing(e))"></el-input>
              </el-form-item>
              <div class="labelWidth2">
                分钟，收费
              </div>
              <el-form-item :prop="'cost_time_period_timing[' + index + '].time_amount'"
                :rules="[{ required: true, message: '请输入计费金额', trigger: 'blur' }, { pattern: /^([1-9]\d*|0)(\.\d{1,2})?$/, message: '请输入带两位小数的数字', trigger: 'blur' }]">
                <el-input class="innerInput" maxlength="9" v-model="item.time_amount"
                  @input="(e) => (item.time_amount = validSpacing(e))"></el-input>
              </el-form-item>元
              <el-button class="buttonCircle" icon="el-icon-plus" size="mini" circle
                @click="addBillingTime(index)"></el-button>
              <el-button class="buttonCircle" icon="el-icon-minus" size="mini" circle
                v-if="index > 0" @click="delBillingTime(index)"></el-button>
            </div>
            <div style="display: inline-block;margin-bottom:10px;display: flex;align-items: center;" v-else>
              <div class="labelWidth">
                之后每
              </div>
              <el-form-item :prop="'cost_time_period_timing[' + index + '].time_duration'"
                :rules="[{ required: true, message: '请输入计费时间', trigger: 'blur' }, { pattern: /^[0-9]*$/, message: '格式错误，请输入数字', trigger: 'blur' }]">
                <el-input class="innerInput" maxlength="9" v-model="item.time_duration"
                  @input="(e) => (item.time_duration = validSpacing(e))"></el-input>
              </el-form-item>
              <div class="labelWidth2">
                分钟，收费
              </div>
              <el-form-item :prop="'cost_time_period_timing[' + index + '].time_amount'"
                :rules="[{ required: true, message: '请输入计费金额', trigger: 'blur' }, { pattern: /^([1-9]\d*|0)(\.\d{1,2})?$/, message: '请输入带两位小数的数字', trigger: 'blur' }]">
                <el-input class="innerInput" maxlength="9" v-model="item.time_amount"
                  @input="(e) => (item.time_amount = validSpacing(e))"></el-input>
              </el-form-item>元
            </div>
          </div>
        </el-form-item>
        <el-form-item label="限额：" prop="is_quota">
          <div class="quota_box">
            <el-select popper-class="my-select" v-model="addForm.is_quota" placeholder="请选择方案类型" class="selectInput">
              <el-option label="未启用" :value="false"></el-option>
              <el-option label="启用" :value="true"></el-option>
            </el-select>
            <div v-if="addForm.is_quota" style="margin-left:15px;display: flex;width: 65%;">
              <el-form-item prop="quota_duration">
                <el-input v-model="addForm.quota_duration" maxlength="9" style="width:150px;margin: 0 5px;"
                  @input="(e) => (addForm.quota_duration = validSpacing(e))"></el-input>
              </el-form-item>
              <div class="labelWidth2">
                分钟，收费
              </div>
              <el-form-item prop="quota_amount">
                <el-input v-model="addForm.quota_amount" maxlength="9" style="width:150px;margin: 0 5px;"
                  @input="(e) => (addForm.quota_amount = validSpacing(e))"></el-input>
              </el-form-item>元
            </div>
          </div>
        </el-form-item>
      </div>
      <el-form-item label="收费：" v-else prop="bout_amount">
        <div style="display: inline-block;">
          <el-input v-model="addForm.bout_amount" maxlength="9" @input="(e) => (addForm.bout_amount = validSpacing(e))"
            placeholder="请输入收费金额"></el-input>
        </div>元
      </el-form-item>
    </el-form>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm()" v-preventReClick>返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm()" v-preventReClick>保 存</lbButton>
  </div>
</template>

<script>
import { getDetailsList, addTimeOrCount, updateTimeOrCount } from '@/api/billingProject'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['addParkId'],
  data () {
    return {
      title: '',
      // 新增/编辑停车场表单
      addForm: {
        _id: '',
        scheme_name: '',
        scheme_type: 1,
        scheme_details: '',
        free_time: 60,
        free_time_iscalculate: true,
        billing_cycle: 1440,
        cost_time_period_timing: [{
          time_duration: '',
          time_amount: ''
        },
        {
          time_duration: '',
          time_amount: ''
        }],
        is_quota: false,
        quota_duration: '',
        quota_amount: '',
        bout_amount: ''
      },
      // 新增/编辑停车场表单验证
      addFormRules: {
        scheme_name: [
          { required: true, message: '请输入方案名称', trigger: 'blur' }
        ],
        scheme_type: [
          { required: true, message: '请选择方案类型', trigger: 'change' }
        ],
        scheme_details: [
          { required: true, message: '请输入方案详情', trigger: 'blur' }
        ],
        free_time: [
          { required: true, message: '请输入免费时长', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '格式错误，请输入数字', trigger: 'blur' }
          // { type: 'number', message: '格式错误，请输入数字型数据', trigger: 'blur' }
        ],
        billing_cycle: [
          { required: true, message: '请输入计费周期', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '格式错误，请输入数字', trigger: 'blur' }
          // { type: 'number', message: '格式错误，请输入数字型数据', trigger: 'blur' }
        ],
        is_quota: [
          { required: true, message: '请选择是否启用限额', trigger: 'change' }
        ],
        quota_duration: [
          { required: true, message: '请输入限额时间', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '格式错误，请输入数字', trigger: 'blur' }
        ],
        quota_amount: [
          { required: true, message: '请输入限额金额', trigger: 'blur' },
          { pattern: /^([1-9]\d*|0)(\.\d{1,2})?$/, message: '请输入带两位小数的数字', trigger: 'blur' }
        ],
        cost_time_period_timing: [
          { type: 'array', required: true, message: '请输入计费时段', trigger: 'blur' }
        ],
        // 计次明细
        bout_amount: [
          { required: true, message: '请输入收费金额', trigger: 'blur' },
          { pattern: /^([1-9]\d*|0)(\.\d{1,2})?$/, message: '请输入带两位小数的数字', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
  },
  mounted () {
    if (this.addParkId) {
      this.title = '编辑临停计费方案'
      this.fnGetDetailsList()
    } else {
      this.title = '新增临停计费方案'
    }
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapActions(['requestPorkingList', 'requestFindList']),
    ...mapMutations(['setQueryStatus']),
    // goBack () {
    //   this.setQueryStatus(2)
    //   this.$router.go(-1)
    // },
    // 获取计时卡信息界面
    async fnGetDetailsList () {
      const res = await getDetailsList({
        time_id: this.addParkId
      })
      if (res && res.Code === 200) {
        // console.log(res.Data);
        if (res.Data.scheme_type === 1) {
          var arr = res.Data.timing_details.cost_time_period_timing.concat()
          for (var i = 0; i < arr.length; i++) {
            arr[i].time_amount = Number(arr[i].time_amount).toFixed(2)
          }
          if (arr.length === 1) {
            arr.push({
              time_duration: '',
              time_amount: ''
            })
          }
        }
        this.addForm._id = res.Data._id
        this.addForm.scheme_name = res.Data.scheme_name
        this.addForm.scheme_type = res.Data.scheme_type
        this.addForm.scheme_details = res.Data.scheme_details
        this.addForm.free_time = res.Data.scheme_type === 1 ? res.Data.timing_details.free_time : ''
        this.addForm.free_time_iscalculate = res.Data.scheme_type === 1 ? res.Data.timing_details.free_time_iscalculate : true
        this.addForm.billing_cycle = res.Data.scheme_type === 1 ? res.Data.timing_details.billing_cycle : ''
        this.addForm.cost_time_period_timing = res.Data.scheme_type === 1 ? arr : [{
          time_duration: '',
          time_amount: ''
        },
        {
          time_duration: '',
          time_amount: ''
        }]
        this.addForm.is_quota = res.Data.scheme_type === 1 ? res.Data.timing_details.is_quota : false
        this.addForm.quota_duration = res.Data.scheme_type === 1 ? (res.Data.timing_details.is_quota ? res.Data.timing_details.quota_duration : '') : ''
        this.addForm.quota_amount = res.Data.scheme_type === 1 ? (res.Data.timing_details.is_quota ? res.Data.timing_details.quota_amount : '') : ''
        this.addForm.bout_amount = res.Data.scheme_type === 2 ? res.Data.bout_details.bout_amount : ''
        // console.log(this.addForm);
      }
    },
    // 添加计费时段
    addBillingTime (index) {
      var timer = {
        time_duration: '',
        time_amount: ''
      }
      this.addForm.cost_time_period_timing.splice(index + 1, 0, timer)
    },
    // 删除计费时段
    delBillingTime (index) {
      this.addForm.cost_time_period_timing.splice(index, 1)
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      // console.log(this.addForm.cost_time_period_timing);
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          // console.log(this.addForm);
          var obj = {}
          // 计时方案
          if (this.addForm.scheme_type === 1) {
            var arr = this.addForm.cost_time_period_timing
            for (var i = 0; i < arr.length; i++) {
              arr[i].time_duration = Number(arr[i].time_duration)
              arr[i].time_amount = parseFloat(arr[i].time_amount).toFixed(2)
            }
            this.addForm.cost_time_period_timing = arr
            this.addForm.quota_amount = parseFloat(this.addForm.quota_amount).toFixed(2)
            obj = {
              scheme_name: this.addForm.scheme_name,
              scheme_type: this.addForm.scheme_type,
              scheme_details: this.addForm.scheme_details,
              timing_details: {
                free_time: Number(this.addForm.free_time),
                free_time_iscalculate: this.addForm.free_time_iscalculate,
                billing_cycle: Number(this.addForm.billing_cycle),
                cost_time_period_timing: this.addForm.cost_time_period_timing,
                is_quota: this.addForm.is_quota,
                quota_duration: this.addForm.is_quota ? Number(this.addForm.quota_duration) : 0,
                quota_amount: this.addForm.is_quota ? this.addForm.quota_amount : 0
              }
            }
          } else { // 计次方案
            this.addForm.bout_amount = parseFloat(this.addForm.bout_amount).toFixed(2)
            obj = {
              scheme_name: this.addForm.scheme_name,
              scheme_type: this.addForm.scheme_type,
              scheme_details: this.addForm.scheme_details,
              bout_details: {
                bout_amount: this.addForm.bout_amount
              }
            }
          }
          if (this.title === '新增临停计费方案') {
            // console.log(obj);
            this.fnAddTimeOrCount(obj)
          } else {
            obj._id = this.addForm._id
            // console.log(obj);
            this.fnUpdateTimeOrCount(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增计时、计次方案请求
    async fnAddTimeOrCount (obj) {
      const res = await addTimeOrCount(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        // this.$router.go(-1)
        this.$emit('closeDialog', false)
      }
    },
    // 编辑计时卡/计次卡方案接口
    async fnUpdateTimeOrCount (obj) {
      const res = await updateTimeOrCount(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
        // this.$router.go(-1)
        // this.goBack()
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped lang="less">
.demo-ruleForm {
  text-align: left;
  max-height: 650px;
  overflow-y: auto;

  .el-textarea,
  .el-select {
    width:90%;
  }

  .el-input {
    width: 90%;
    margin-right: 10px;
  }

  .quota_box {
    display: flex;
    .selectInput{
      width: 23%;
    }
  }
.labelWidth{
  min-width: 60px;
}
.labelWidth2{
  min-width: 90px;
}
.innerInput{
width:90%;margin: 0 15px;
}
.buttonCircle{
  margin-left:15px;padding:8px!important;
}
  /* 使用webkit内核的浏览器 */
  textarea::-webkit-input-placeholder {
    color: #c0c4cc;
  }

  /* Firefox版本4-18 */
  textarea:-moz-placeholder {
    color: #c0c4cc;
  }

  textarea::-moz-placeholder {
    color: #c0c4cc;
  }

  /* Firefox版本19+ */
  textarea:-ms-input-placeholder {
    color: #c0c4cc;
  }

  textarea:focus {
    border-color: #f56c6c;
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}
</style>
